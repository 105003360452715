/* eslint-disable no-param-reassign */
import { createContext, useState, useEffect } from "react";
// import DB from "scripts/DB";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// PFA
import DB from "scripts/DB";

// create context
const SearchAndFilterContext = createContext();

// eslint-disable-next-line react/function-component-definition
const SearchAndFilterContextProvider = ({ children }) => {
  const [currentSearchBarSelection, setCurrentSearchBarSelection] = useState({
    action: "donate",
    subject: "food and drinks",
    target: "poor people",
  }); // Holds the currently selected search bar option for action, subject, target
  const [searchBarOptions, setSearchBarOptions] = useState({}); // Holds the search bar options for actions, subjects, targets which are used by at least 1 organization
  const [currentSearchValues, setCurrentSearchValues] = useState();
  const [resultingOrgIDs, setResultingOrgIDs] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  // Download the search bar options
  useEffect(async () => {
    const [searchActions, searchSubjects, searchTargets] = await Promise.all([
      DB.GetHelpActionValues(),
      DB.GetHelpSubjectValues(),
      DB.GetHelpTargetValues(),
    ]);
    setSearchBarOptions({
      loaded: true,
      actions: searchActions,
      subjects: searchSubjects,
      targets: searchTargets,
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("Filter options:");
    // eslint-disable-next-line no-console
    console.log(filterOptions.subjects);
    // eslint-disable-next-line no-console
    console.log(filterOptions.targets);
    // eslint-disable-next-line no-console
    console.log(filterOptions.locations);
  }, [filterOptions]);

  async function getSearchResults(values) {
    const searchResultFromAPI = await DB.GetSearchResults(
      values.actionID,
      values.subjectID,
      values.targetID
    );
    setResultingOrgIDs(searchResultFromAPI.orgIDs.map((element) => element.OrgID));
    setFilterOptions({
      loaded: true,
      subjects: searchResultFromAPI.filterOptions.subjects,
      targets: searchResultFromAPI.filterOptions.targets,
      locations: searchResultFromAPI.filterOptions.locations,
    });
  }

  function updateSearchBarSelection(values) {
    setCurrentSearchBarSelection({
      action: values.action,
      subject: values.subject,
      target: values.target,
    });
  }

  function searchButtonPressed() {
    setCurrentSearchValues({
      actionName: currentSearchBarSelection.action,
      actionID: searchBarOptions.actions.find(
        (x) => x.itemName === currentSearchBarSelection.action
      ).itemID,
      subjectName: currentSearchBarSelection.subject,
      subjectID: searchBarOptions.subjects.find(
        (x) => x.itemName === currentSearchBarSelection.subject
      ).itemID,
      targetName: currentSearchBarSelection.target,
      targetID: searchBarOptions.targets.find(
        (x) => x.itemName === currentSearchBarSelection.target
      ).itemID,
    });
    // there is a useEffect hook above (or below), which tracks the changes on currentSearchValues and makes an API call for search results.
  }

  useEffect(() => {
    if (currentSearchValues) {
      getSearchResults(currentSearchValues);
    }
  }, [currentSearchValues]);

  // Functions for managing the checked state of the filters:
  // ********************************************************
  function filtersDoesAllChildrenHaveSameCheckedValue(parentElement) {
    const checkedValue = parentElement.items[0].checked;
    if (parentElement.items.every((z) => z.checked === checkedValue)) {
      return true;
    }
    return false;
  }

  function filtersAreThereAnyIndeterminateAmongstChildren(parentElement) {
    if (parentElement.items.some((z) => z.indeterminate === true)) {
      return true;
    }
    return false;
  }

  const filterScopeDeepestLevel = (element) => {
    let scopeDeepestLevel = 0;
    if (filterOptions[element.scope][0].items?.length > 0) {
      if (filterOptions[element.scope][0].items[0].items?.length > 0) {
        scopeDeepestLevel = 2;
      } else {
        scopeDeepestLevel = 1;
      }
    }
    return scopeDeepestLevel;
  };

  const doesScopeHaveOnlyOneCheckedElement = (element) => {
    const scopeDeepestLevel = filterScopeDeepestLevel(element);
    let checkedElements = [];

    if (scopeDeepestLevel === 0) {
      checkedElements = filterOptions[element.scope]
        .filter((x) => x.checked === true)
        .map((x) => x);
    }

    if (scopeDeepestLevel === 1) {
      filterOptions[element.scope].forEach(
        // eslint-disable-next-line no-return-assign
        (x) =>
          (checkedElements = [
            ...checkedElements,
            ...x.items.filter((y) => y.checked === true).map((z) => z),
          ])
      );
    }

    if (scopeDeepestLevel === 2) {
      filterOptions[element.scope].forEach((x) =>
        x.items.forEach(
          // eslint-disable-next-line no-return-assign
          (y) =>
            (checkedElements = [
              ...checkedElements,
              ...y.items.filter((z) => z.checked === true).map((q) => q),
            ])
        )
      );
    }

    if (checkedElements.length === 1) {
      // eslint-disable-next-line no-console
      console.log(checkedElements[0]);
      return checkedElements[0];
    }

    return {};
  };

  const filtersHandleCheckedState = (element) => {
    // is there only one child element which is selected in this scope and did the user click on it? Don't uncheck it!
    const scopeLastElement = doesScopeHaveOnlyOneCheckedElement(element);
    if (scopeLastElement.itemID && element.key === scopeLastElement.key) {
      return;
    }
    // ok, there is more than one selection in this scope.
    // Check if the clicked element is a parent (or grandparent), check if it's .checked, check if it's the other parents are unchecked.
    if (element.level !== scopeLastElement.level && element.checked) {
      if (element.level === 0) {
        const otherCheckedOrIndeterminateParents = filterOptions[element.scope].filter(
          (x) => x.itemID !== element.itemID && (x.checked || x.indeterminate)
        );
        if (otherCheckedOrIndeterminateParents.length === 0) {
          return;
        }
      }
      if (element.level === 1) {
        const parentElement = filterOptions[element.scope].filter(
          (y) => y.key === element.parentKey[0]
        );
        const parentElementOtherChildrenWithCheckedChildren = parentElement[0].items.filter(
          (x) => x.itemID !== element.itemID && (x.checked || x.indeterminate)
        );

        if (parentElementOtherChildrenWithCheckedChildren.length === 0) {
          return;
        }
      }
    }

    // copy filterValues to manipulate individual elements:
    const newFilterValues = filterOptions[element.scope].map((x) => x);
    // find the element in the array using key and level
    // level 0:
    if (element.level === 0) {
      const indexOfElement = newFilterValues.findIndex((x) => x.key === element.key);
      if (indexOfElement !== -1) {
        newFilterValues[indexOfElement].checked = !newFilterValues[indexOfElement].checked;
        newFilterValues[indexOfElement].indeterminate = false; // turn of indeterminate as the user clicked on the checkbox, and want it either checked or unchecked.
        // All children should also have the same checked state:
        newFilterValues[indexOfElement].items?.forEach((x) => {
          x.checked = newFilterValues[indexOfElement].checked;
          x.indeterminate = false;
          x.items?.forEach((y) => {
            y.checked = newFilterValues[indexOfElement].checked;
            y.indeterminate = false;
          });
        });
        const newFullFilterOptions = { ...filterOptions, [element.scope]: newFilterValues };
        setFilterOptions(newFullFilterOptions);
      }
    }
    // level 1:
    if (element.level === 1) {
      const indexOfParentElement = newFilterValues.findIndex((x) => x.key === element.parentKey[0]);
      if (indexOfParentElement !== -1) {
        const indexOfElement = newFilterValues[indexOfParentElement].items.findIndex(
          (x) => x.key === element.key
        );
        if (indexOfElement !== -1) {
          newFilterValues[indexOfParentElement].items[indexOfElement].checked =
            !newFilterValues[indexOfParentElement].items[indexOfElement].checked;
          newFilterValues[indexOfParentElement].items[indexOfElement].indeterminate = false; // turn of indeterminate as the user clicked on the checkbox, and want it either checked or unchecked.
          // All children should also have the same checked state:
          newFilterValues[indexOfParentElement].items[indexOfElement].items?.forEach((y) => {
            y.checked = newFilterValues[indexOfParentElement].items[indexOfElement].checked;
          });
          // Check if all peers have the same checked value, and if so, make parent's checked value equal to it
          if (
            filtersDoesAllChildrenHaveSameCheckedValue(newFilterValues[indexOfParentElement]) &&
            !filtersAreThereAnyIndeterminateAmongstChildren(newFilterValues[indexOfParentElement])
          ) {
            newFilterValues[indexOfParentElement].indeterminate = false; // Also make sure the parent IS NOT indeterminate
            newFilterValues[indexOfParentElement].checked =
              newFilterValues[indexOfParentElement].items[indexOfElement].checked;
          } else {
            newFilterValues[indexOfParentElement].indeterminate = true; // Also make sure the parent IS indeterminate
            newFilterValues[indexOfParentElement].checked = false;
          }
          const newFullFilterOptions = { ...filterOptions, [element.scope]: newFilterValues };
          setFilterOptions(newFullFilterOptions);
        }
      }
    }
    // level 2:
    if (element.level === 2) {
      const indexOfParentParentElement = newFilterValues.findIndex(
        (x) => x.key === element.parentKey[0]
      );
      if (indexOfParentParentElement !== -1) {
        const indexOfParentElement = newFilterValues[indexOfParentParentElement].items.findIndex(
          (x) => x.key === element.parentKey[1]
        );
        if (indexOfParentElement !== -1) {
          const indexOfElement = newFilterValues[indexOfParentParentElement].items[
            indexOfParentElement
          ].items.findIndex((x) => x.key === element.key);
          if (indexOfElement !== -1) {
            newFilterValues[indexOfParentParentElement].items[indexOfParentElement].items[
              indexOfElement
            ].checked =
              !newFilterValues[indexOfParentParentElement].items[indexOfParentElement].items[
                indexOfElement
              ].checked;
            newFilterValues[indexOfParentParentElement].items[indexOfParentElement].items[
              indexOfElement
            ].indeterminate = false; // turn of indeterminate as the user clicked on the checkbox, and want it either checked or unchecked.
            // Check if all peers have the same checked value, and if so, make parent's checked value equal to it
            if (
              filtersDoesAllChildrenHaveSameCheckedValue(
                newFilterValues[indexOfParentParentElement].items[indexOfParentElement]
              )
            ) {
              newFilterValues[indexOfParentParentElement].items[
                indexOfParentElement
              ].indeterminate = false; // Also make sure the parent IS NOT indeterminate
              newFilterValues[indexOfParentParentElement].items[indexOfParentElement].checked =
                newFilterValues[indexOfParentParentElement].items[indexOfParentElement].items[
                  indexOfElement
                ].checked;
            } else {
              newFilterValues[indexOfParentParentElement].items[
                indexOfParentElement
              ].indeterminate = true; // Also make sure the parent IS indeterminate
              newFilterValues[indexOfParentParentElement].items[
                indexOfParentElement
              ].checked = false;
            }
            // Now check if all peers of the parent have the same checked value, and if so, make parentparent's checked value equal to it
            if (
              filtersDoesAllChildrenHaveSameCheckedValue(
                newFilterValues[indexOfParentParentElement]
              ) &&
              !filtersAreThereAnyIndeterminateAmongstChildren(
                newFilterValues[indexOfParentParentElement]
              )
            ) {
              newFilterValues[indexOfParentParentElement].indeterminate = false; // Also make sure the parent IS NOT indeterminate
              newFilterValues[indexOfParentParentElement].checked =
                newFilterValues[indexOfParentParentElement].items[indexOfParentElement].checked;
            } else {
              newFilterValues[indexOfParentParentElement].indeterminate = true; // Also make sure the parent IS indeterminate
              newFilterValues[indexOfParentParentElement].checked = false;
            }

            const newFullFilterOptions = { ...filterOptions, [element.scope]: newFilterValues };
            setFilterOptions(newFullFilterOptions);
          }
        }
      }
    }
  };

  function filtersGetCheckedState(element) {
    if (
      element.level === 0 &&
      filterOptions[element.scope].find((x) => x.key === element.key).checked === true
    ) {
      return true;
    }
    if (element.level === 1) {
      if (
        filterOptions[element.scope]
          .find((x) => x.key === element.parentKey[0])
          .items?.find((y) => y.key === element.key).checked === true
      ) {
        return true;
      }
    }
    if (element.level === 2) {
      if (
        filterOptions[element.scope]
          .find((x) => x.key === element.parentKey[0])
          .items?.find((y) => y.key === element.parentKey[1])
          .items?.find((z) => z.key === element.key).checked === true
      ) {
        return true;
      }
    }
    return false;
  }

  function filtersGetIndeterminateState(element) {
    // updateCheckedStatesFromContext();
    if (
      element.level === 0 &&
      filterOptions[element.scope].find((x) => x.key === element.key).indeterminate === true
    ) {
      return true;
    }
    if (element.level === 1) {
      if (
        filterOptions[element.scope]
          .find((x) => x.key === element.parentKey[0])
          .items?.find((y) => y.key === element.key).indeterminate === true
      ) {
        return true;
      }
    }
    if (element.level === 2) {
      if (
        filterOptions[element.scope]
          .find((x) => x.key === element.parentKey[0])
          .items?.find((y) => y.key === element.parentKey[1])
          .items?.find((z) => z.key === element.key).indeterminate === true
      ) {
        return true;
      }
    }
    return false;
  }

  async function applyFiltersButtonPressed() {
    const targetFilterIDs = filterOptions.targets
      .filter((x) => x.checked === true)
      .map((x) => x.itemID);

    let subjectFilterIDs = [];
    filterOptions.subjects.forEach(
      // eslint-disable-next-line no-return-assign
      (x) =>
        (subjectFilterIDs = [
          ...subjectFilterIDs,
          ...x.items.filter((y) => y.checked === true).map((z) => z.itemID),
        ])
    );

    let locationFilterIDs = [];
    filterOptions.locations.forEach((x) =>
      x.items.forEach(
        // eslint-disable-next-line no-return-assign
        (y) =>
          (locationFilterIDs = [
            ...locationFilterIDs,
            ...y.items.filter((z) => z.checked === true).map((q) => q.itemID),
          ])
      )
    );

    const filteredSearchResultFromAPI = await DB.GetFilteredResults(
      currentSearchValues.actionID,
      currentSearchValues.subjectID,
      currentSearchValues.targetID,
      targetFilterIDs,
      subjectFilterIDs,
      locationFilterIDs
    );
    // eslint-disable-next-line no-console
    console.log(filteredSearchResultFromAPI);
    setResultingOrgIDs(filteredSearchResultFromAPI.orgIDs.map((element) => element.OrgID));
  }

  return (
    // the Provider gives access to the context to its children
    <SearchAndFilterContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        currentSearchValues,
        searchResults: resultingOrgIDs,
        searchBarOptions,
        currentSearchBarSelection,
        filterOptions,
        updateSearchBarSelection,
        searchButtonPressed,
        filtersHandleCheckedState,
        filtersGetCheckedState,
        filtersGetIndeterminateState,
        applyFiltersButtonPressed,
      }}
    >
      {children}
    </SearchAndFilterContext.Provider>
  );
};

export { SearchAndFilterContext, SearchAndFilterContextProvider };

SearchAndFilterContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
