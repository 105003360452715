/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";
import PFAEntryPage from "layouts/pages/pfaEntry";
import PFASearchResults from "layouts/pages/pfaSearchResults";
import PFAOrgInfoPage from "layouts/pages/pfaOrgInfo";

// Material Kit 2 PRO React routes
import routes from "routes";

// PFA Contexts
import { SearchAndFilterContextProvider } from "scripts/contexts/SearchAndFilterContext/index";

// Search bar selected items context

export default function App() {
  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [languageState, setLanguageState] = useState("EN");

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <SearchAndFilterContextProvider>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<PFAEntryPage />} />
          <Route path="/search" element={<PFASearchResults />} />
          <Route path="/orginfo/:id" element={<PFAOrgInfoPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </SearchAndFilterContextProvider>
    </ThemeProvider>
  );
}
