import React, { useState, useEffect } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// react-router components
import { Link } from "react-router-dom";

// Material Kit 2 PRO React components
import MKSocialButton from "components/MKSocialButton";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons-material components
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WebIcon from "@mui/icons-material/Web";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import LocationIcon from "@mui/icons-material/LocationOn";

import PFAActionButton from "components/PFAActionButton";
import cardBack from "assets/images/bg/1pxWhiteBackground.png";
import DB from "scripts/DB";

export default function PFAOrganizationInfoCard(props) {
  const { orgID, size } = props;

  const [orgDetails, setStateOrgDetails] = useState("");
  const [orgContactAddress, setStateContactAddress] = useState("");
  useEffect(async () => {
    const [orgDetailsFromAPI, orgContactFromAPI] = await Promise.all([
      DB.GetOrgDetails(orgID),
      DB.GetOrgContactAddress(orgID),
    ]);
    setStateOrgDetails(orgDetailsFromAPI);
    setStateContactAddress(orgContactFromAPI);
  }, []);

  if ((orgDetails === "" || orgContactAddress === "") && size === "lg") {
    return <Grid container>Loading organization information...</Grid>;
  }
  if ((orgDetails === "" || orgContactAddress === "") && size === "md") {
    return (
      <Card
        sx={{
          p: 2,
          my: 2,
          height: { lg: "250px" },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        Loading organization information...
      </Card>
    );
  }
  if (size === "lg") {
    return (
      // TODO: Asagidakileri "product page" den ilham alarak yeniden duzenleyebilirsin sonradan, ustune basilinca acilan kapanan yapiyi sevdim. Ayrica urun karti da guzel.
      <Grid container spacing={5}>
        <Grid item sm={12} md={4} mx="auto" p={2}>
          {/*------------------------------------*/}
          {/* Logo, established date and mission */}
          {/*------------------------------------*/}

          <MKBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <MKBox shadow="xl">
              <a href={orgDetails.MainWebsite} target="blank">
                <img src={orgDetails.OrgLogoPath} alt="..." width="100%" />
              </a>
            </MKBox>
            <div
              style={{
                backgroundImage: `url(${cardBack})`,
                opacity: "1",
              }}
            />
            <br />
            <MKTypography variant="h3" align="center">
              <a href={orgDetails.MainWebsite} target="blank">
                {orgDetails.OrgName_EN}
              </a>
            </MKTypography>
            {orgDetails.DateEstablished !== null ? (
              <MKTypography variant="body2" align="center">
                Since {orgDetails.DateEstablished}
              </MKTypography>
            ) : (
              <br />
            )}

            {/* TODO: Format the date to show month and year only */}
            <br />
            <MKTypography variant="body1" align="center">
              {orgDetails.OrgMission_EN}
            </MKTypography>
          </MKBox>
          <br />

          {/*--------------------*/}
          {/* PFA Action Buttons */}
          {/*--------------------*/}

          <MKBox
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >
            {orgDetails.IsVolunteeringPossible === true ? (
              <MKBox m={1} width="85%">
                <PFAActionButton actionType="volunteer" color="volunteering" fullWidth />
              </MKBox>
            ) : (
              ""
            )}
            {orgDetails.IsGoodsDonationPossible === true &&
            (orgDetails.beneficiaryTypes.includes("adults") ||
              orgDetails.beneficiaryTypes.includes("generic") ||
              orgDetails.beneficiaryTypes.includes("environment")) ? (
              <MKBox m={1} width="85%">
                <PFAActionButton actionType="donateGoodsPeople" color="warning" fullWidth />
              </MKBox>
            ) : (
              ""
            )}
            {orgDetails.IsGoodsDonationPossible === true &&
            orgDetails.beneficiaryTypes.includes("children") ? (
              <MKBox m={1} width="85%">
                <PFAActionButton actionType="donateGoodsChildren" color="warning" fullWidth />
              </MKBox>
            ) : (
              ""
            )}
            {orgDetails.IsGoodsDonationPossible === true &&
            orgDetails.beneficiaryTypes.includes("animals") ? (
              <MKBox m={1} width="85%">
                <PFAActionButton actionType="donateGoodsAnimals" color="warning" fullWidth />
              </MKBox>
            ) : (
              ""
            )}
            {orgDetails.IsMonetaryDonationPossible === true ? (
              <MKBox m={1} width="85%">
                <PFAActionButton actionType="donateCash" color="warning" fullWidth />
              </MKBox>
            ) : (
              ""
            )}
            {orgDetails.IsOnePercentTaxDonationPossible === true ? (
              <MKBox m={1} width="85%">
                <PFAActionButton actionType="donateOnePercent" color="taxOffice" fullWidth />
              </MKBox>
            ) : (
              ""
            )}
          </MKBox>
          <br />

          {/*---------------------*/}
          {/* Contact Information */}
          {/*---------------------*/}

          <div>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <MKTypography variant="body2" align="left">
                  <LocationIcon /> Contact address:
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MKTypography variant="body2" align="right">
                  {orgContactAddress.Street} {orgContactAddress.HouseNumber}
                  {orgContactAddress.ApartmentNumber !== null ? (
                    <span>/{orgContactAddress.ApartmentNumber}</span>
                  ) : (
                    ""
                  )}
                  <br />
                  {orgContactAddress.ZipCode !== null ? (
                    <span>
                      {orgContactAddress.ZipCode}
                      <br />
                    </span>
                  ) : (
                    ""
                  )}
                  {orgContactAddress.addressCityName}/{orgContactAddress.addressStateName}/
                  {orgContactAddress.addressCountryName}
                </MKTypography>
              </Grid>
            </Grid>
            <br />

            {orgContactAddress.TelephoneNumber1 !== null ? (
              <div>
                <Grid container>
                  <Grid item xs={12} lg={7}>
                    <MKTypography variant="body2" align="left">
                      <PhoneIcon /> {orgContactAddress.TelephoneNumber1Description}:
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <MKTypography variant="body2" align="right">
                      +{orgContactAddress.telephone1CountryCode}{" "}
                      {orgContactAddress.TelephoneNumber1}
                    </MKTypography>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              ""
            )}

            {orgContactAddress.TelephoneNumber2 !== null ? (
              <div>
                <Grid container>
                  <Grid item xs={12} lg={7}>
                    <MKTypography variant="body2" align="left">
                      <PhoneIcon /> {orgContactAddress.TelephoneNumber2Description}:
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <MKTypography variant="body2" align="right">
                      +{orgContactAddress.telephone2CountryCode}{" "}
                      {orgContactAddress.TelephoneNumber2}
                    </MKTypography>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              ""
            )}

            {orgContactAddress.TelephoneNumber3 !== null ? (
              <div>
                <Grid container>
                  <Grid item xs={12} lg={7}>
                    <MKTypography variant="body2" align="left">
                      <PhoneIcon /> {orgContactAddress.TelephoneNumber3Description}:
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <MKTypography variant="body2" align="right">
                      +{orgContactAddress.telephone3CountryCode}{" "}
                      {orgContactAddress.TelephoneNumber3}
                    </MKTypography>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              ""
            )}

            {orgContactAddress.FaxNumber1 !== null ? (
              <div>
                <Grid container>
                  <Grid item xs={12} lg={7}>
                    <MKTypography variant="body2" align="left">
                      <FaxIcon /> {orgContactAddress.FaxNumber1Description}:
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <MKTypography variant="body2" align="right">
                      +{orgContactAddress.fax1CountryCode} {orgContactAddress.FaxNumber1}
                    </MKTypography>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              ""
            )}

            {orgContactAddress.FaxNumber2 !== null ? (
              <div>
                <Grid container>
                  <Grid item xs={12} lg={7}>
                    <MKTypography variant="body2" align="left">
                      <FaxIcon /> {orgContactAddress.FaxNumber2Description}:
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <MKTypography variant="body2" align="right">
                      +{orgContactAddress.fax2CountryCode} {orgContactAddress.FaxNumber2}
                    </MKTypography>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              ""
            )}

            {orgContactAddress.FaxNumber3 !== null ? (
              <div>
                <Grid container>
                  <Grid item xs={12} lg={7}>
                    <MKTypography variant="body2" align="left">
                      <FaxIcon /> {orgContactAddress.FaxNumber3Description}:
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <MKTypography variant="body2" align="right">
                      +{orgContactAddress.fax3CountryCode} {orgContactAddress.FaxNumber3}
                    </MKTypography>
                  </Grid>
                </Grid>
                <br />
              </div>
            ) : (
              ""
            )}
          </div>

          {/*-----------------*/}
          {/* Social buttons: */}
          {/*-----------------*/}

          <Grid container spacing={1} justifyContent="center">
            {orgDetails.MainWebsite !== null ? (
              <Grid item>
                <MKSocialButton
                  href={orgDetails.MainWebsite}
                  target="_blank"
                  color="rose"
                  iconOnly
                  circular
                >
                  <WebIcon />
                </MKSocialButton>
              </Grid>
            ) : (
              ""
            )}
            {orgDetails.MainEmail !== null ? (
              <Grid item>
                <MKSocialButton
                  href={`mailto:${orgDetails.MainEmail}`}
                  target="_blank"
                  color="info"
                  iconOnly
                  circular
                >
                  <EmailIcon />
                </MKSocialButton>
              </Grid>
            ) : (
              ""
            )}
            {orgDetails.MainInstagramProfile !== null ? (
              <Grid item>
                <MKSocialButton
                  href={orgDetails.MainInstagramProfile}
                  target="_blank"
                  color="instagram"
                  iconOnly
                  circular
                >
                  <InstagramIcon />
                </MKSocialButton>
              </Grid>
            ) : (
              ""
            )}
            {orgDetails.MainTwitterProfile !== null ? (
              <Grid item>
                <MKSocialButton
                  href={orgDetails.MainTwitterProfile}
                  target="_blank"
                  color="twitter"
                  iconOnly
                  circular
                >
                  <TwitterIcon />
                </MKSocialButton>
              </Grid>
            ) : (
              ""
            )}
            {orgDetails.MainFacebookPage !== null ? (
              <Grid item>
                <MKSocialButton
                  href={orgDetails.MainFacebookPage}
                  target="_blank"
                  color="facebook"
                  iconOnly
                  circular
                >
                  <FacebookIcon />
                </MKSocialButton>
              </Grid>
            ) : (
              ""
            )}
            {orgDetails.MainYoutubeChannel !== null ? (
              <Grid item>
                <MKSocialButton
                  href={orgDetails.MainYoutubeChannel}
                  target="_blank"
                  color="youtube"
                  iconOnly
                  circular
                >
                  <YouTubeIcon />
                </MKSocialButton>
              </Grid>
            ) : (
              ""
            )}
            {orgDetails.MainLinkedInProfile !== null ? (
              <Grid item>
                <MKSocialButton
                  href={orgDetails.MainLinkedInProfile}
                  target="_blank"
                  color="linkedin"
                  iconOnly
                  circular
                >
                  <LinkedInIcon />
                </MKSocialButton>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid item sm={12} md={8} mx="auto" p={2}>
          <MKTypography variant="h4">Introduction</MKTypography>
          <br />
          {/* TODO: Check if the paragraphs are separated nicely */}
          {/* <h4 className={classes.title}>Animal Admission and Adoption Office</h4> -> may be useful later, delete if not */}
          {orgDetails.OrgIntroduction_EN !== null ? (
            <span>
              <MKTypography variant="body2">{orgDetails.OrgIntroduction_EN}</MKTypography>
            </span>
          ) : (
            <span>
              This organization has not provided an introduction yet, please check back later.
            </span>
          )}
          {orgDetails.OrgAdditionalInformation_EN !== null ? (
            <div>
              <br />
              <MKTypography variant="h4">Additional Information</MKTypography>
              <br />
              <MKTypography variant="body2">{orgDetails.OrgAdditionalInformation_EN}</MKTypography>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  }
  if (size === "md") {
    const action = {
      type: "internal",
      route: `/OrgInfo/${orgID}`,
    };
    const shadowed = true;

    return (
      // TODO: Asagidakileri "product page" den ilham alarak yeniden duzenleyebilirsin sonradan, ustune basilinca acilan kapanan yapiyi sevdim. Ayrica urun karti da guzel.
      <Card
        sx={{
          p: 2,
          my: 2,
          height: { lg: "250px" },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={2}>
          {/*------*/}
          {/* Logo */}
          {/*------*/}
          <Grid item xs={12} lg={3}>
            <Grid container justifyContent="center">
              <Grid item>
                <Link to={action.route}>
                  <MKBox width="210px" height="210px">
                    <MKBox
                      component="img"
                      src={orgDetails.OrgLogoPath}
                      alt={orgDetails.OrgName_EN}
                      borderRadius="lg"
                      shadow={shadowed ? "lg" : "none"}
                      maxWidth="100%"
                      maxHeight="100%"
                      width="100%"
                      height="100%"
                      zIndex={1}
                      p={1}
                      sx={{
                        objectFit: "contain",
                      }}
                    />
                  </MKBox>
                </Link>
              </Grid>
            </Grid>

            <div
              style={{
                backgroundImage: `url(${cardBack})`,
                opacity: "1",
              }}
            />
          </Grid>

          {/*-------------------------------------------*/}
          {/* Name, established date, mission, social buttons */}
          {/*-------------------------------------------*/}
          <Grid item xs={12} lg={5} mx="auto">
            <Link to={action.route}>
              <MKTypography variant="h5" align="left">
                {orgDetails.OrgName_EN}
              </MKTypography>
            </Link>
            {orgDetails.DateEstablished !== null ? (
              <MKTypography variant="overline" align="left">
                Since {orgDetails.DateEstablished}
              </MKTypography>
            ) : (
              <br />
            )}

            {/* TODO: Format the date to show month and year only */}

            {/*---------*/}
            {/* Mission */}
            {/*---------*/}
            <MKTypography variant="body2" component="p" color="text">
              {orgDetails.OrgMission_EN.replace(/(.{145})..+/, "$1…")}
            </MKTypography>

            {/*-----------------*/}
            {/* Social buttons: */}
            {/*-----------------*/}
            <Grid container spacing={1} justifyContent="left" pt={1}>
              {orgDetails.MainWebsite !== null ? (
                <Grid item>
                  <MKSocialButton
                    href={orgDetails.MainWebsite}
                    target="_blank"
                    color="rose"
                    iconOnly
                    circular
                  >
                    <WebIcon />
                  </MKSocialButton>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.MainEmail !== null ? (
                <Grid item>
                  <MKSocialButton
                    href={`mailto:${orgDetails.MainEmail}`}
                    target="_blank"
                    color="info"
                    iconOnly
                    circular
                  >
                    <EmailIcon />
                  </MKSocialButton>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.MainInstagramProfile !== null ? (
                <Grid item>
                  <MKSocialButton
                    href={orgDetails.MainInstagramProfile}
                    target="_blank"
                    color="instagram"
                    iconOnly
                    circular
                  >
                    <InstagramIcon />
                  </MKSocialButton>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.MainTwitterProfile !== null ? (
                <Grid item>
                  <MKSocialButton
                    href={orgDetails.MainTwitterProfile}
                    target="_blank"
                    color="twitter"
                    iconOnly
                    circular
                  >
                    <TwitterIcon />
                  </MKSocialButton>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.MainFacebookPage !== null ? (
                <Grid item>
                  <MKSocialButton
                    href={orgDetails.MainFacebookPage}
                    target="_blank"
                    color="facebook"
                    iconOnly
                    circular
                  >
                    <FacebookIcon />
                  </MKSocialButton>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.MainYoutubeChannel !== null ? (
                <Grid item>
                  <MKSocialButton
                    href={orgDetails.MainYoutubeChannel}
                    target="_blank"
                    color="youtube"
                    iconOnly
                    circular
                  >
                    <YouTubeIcon />
                  </MKSocialButton>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.MainLinkedInProfile !== null ? (
                <Grid item>
                  <MKSocialButton
                    href={orgDetails.MainLinkedInProfile}
                    target="_blank"
                    color="linkedin"
                    iconOnly
                    circular
                  >
                    <LinkedInIcon />
                  </MKSocialButton>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          {/*--------------------*/}
          {/* PFA Action Buttons */}
          {/*--------------------*/}
          <Grid item xs={12} lg={4} mx="auto">
            <Grid container spacing={0} justifyContent="center">
              {orgDetails.IsVolunteeringPossible === true ? (
                <Grid item>
                  <MKBox m={1} width="85%">
                    <PFAActionButton
                      actionType="volunteer"
                      color="volunteering"
                      fullWidth
                      size="small"
                    />
                  </MKBox>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.IsGoodsDonationPossible === true &&
              (orgDetails.beneficiaryTypes.includes("adults") ||
                orgDetails.beneficiaryTypes.includes("generic") ||
                orgDetails.beneficiaryTypes.includes("environment")) ? (
                <Grid item>
                  <MKBox m={1} width="85%">
                    <PFAActionButton
                      actionType="donateGoodsPeople"
                      color="warning"
                      fullWidth
                      size="small"
                    />
                  </MKBox>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.IsGoodsDonationPossible === true &&
              orgDetails.beneficiaryTypes.includes("children") ? (
                <Grid item>
                  <MKBox m={1} width="85%">
                    <PFAActionButton
                      actionType="donateGoodsChildren"
                      color="warning"
                      fullWidth
                      size="small"
                    />
                  </MKBox>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.IsGoodsDonationPossible === true &&
              orgDetails.beneficiaryTypes.includes("animals") ? (
                <Grid item>
                  <MKBox m={1} width="85%">
                    <PFAActionButton
                      actionType="donateGoodsAnimals"
                      color="warning"
                      fullWidth
                      size="small"
                    />
                  </MKBox>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.IsMonetaryDonationPossible === true ? (
                <Grid item>
                  <MKBox m={1} width="85%">
                    <PFAActionButton
                      actionType="donateCash"
                      color="warning"
                      fullWidth
                      size="small"
                    />
                  </MKBox>
                </Grid>
              ) : (
                ""
              )}
              {orgDetails.IsOnePercentTaxDonationPossible === true ? (
                <Grid item>
                  <MKBox m={1} width="85%">
                    <PFAActionButton
                      actionType="donateOnePercent"
                      color="taxOffice"
                      fullWidth
                      size="small"
                    />
                  </MKBox>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

PFAOrganizationInfoCard.propTypes = {
  orgID: PropTypes.number,
  size: PropTypes.oneOf(["sm", "md", "lg"]).isRequired,
};

PFAOrganizationInfoCard.defaultProps = {
  orgID: 0,
};
