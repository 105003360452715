/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// PFA Components
import PFASearchBar from "components/PFASearchBar";
import PFANavbar from "components/PFANavbar";
import PFAFooter from "components/PFAFooter";
import PFANewsletter from "layouts/sections/pfanewsletter";
import PFAOrgInfoCard from "components/PFAOrgInfoCard";

// Presentation page sections
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { useParams } from "react-router-dom";

// Images
import bgImage from "assets/images/bg/kelly-sikkema-XX2WTbLr3r8-unsplash.jpg";

function PFAOrgInfo() {
  const { id } = useParams();

  return (
    <>
      <PFANavbar
        routes={routes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-pro-react",
        //   label: "log in",
        //   color: "info",
        // }}
      />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <PFASearchBar type="categorical" />
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          pt: 5,
          pb: 5,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <PFAOrgInfoCard orgID={Number(id)} size="lg" />
        </Container>
      </Card>
      <PFANewsletter />
      <MKBox pt={2} px={1} mt={2}>
        <PFAFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PFAOrgInfo;
