/* eslint-disable prettier/prettier */
/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// PFA Components
import PFANavbar from "components/PFANavbar";
import PFAFooter from "components/PFAFooter";

// Presentation page sections

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg/kelly-sikkema-XX2WTbLr3r8-unsplash.jpg";

function PFAPrivacy() {
  return (
    <>
      <PFANavbar routes={routes} sticky />
      <MKBox
        minHeight="25vh"
        width="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <MKBox component="section" pt={{ xs: 12, lg: 16 }} pb={{ xs: 8, lg: 12 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  py={3}
                  px={{ xs: 2, sm: 3 }}
                  mt={-3}
                  mx={{ xs: 1, lg: 2 }}
                >
                  <MKTypography variant="h3" color="white">
                    Privacy Policy for peopleforall.com
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: February 27, 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={{ xs: 2, lg: 6 }}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    At peopleforall.com, accessible from https://peopleforall.com, one of our main
                    priorities is the privacy of our visitors. This Privacy Policy document
                    contains types of information that is collected and recorded by
                    peopleforall.com and how we use it.
                    <br />
                    <br />
                    If you have additional questions or require more information about our Privacy
                    Policy, do not hesitate to contact us.
                    <br />
                    <br />
                    This Privacy Policy applies only to our online activities and is valid for
                    visitors to our website with regards to the information that they shared
                    and/or collect in peopleforall.com. This policy is not applicable to any
                    information collected offline or via channels other than this website. Our
                    Privacy Policy was created with the help of the Free Privacy Policy Generator.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Consent
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    By using our website, you hereby consent to our Privacy Policy and agree to
                    its terms.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Information we collect
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    The personal information that you are asked to provide, and the reasons why
                    you are asked to provide it, will be made clear to you at the point we ask you
                    to provide your personal information.
                    <br />
                    <br />
                    If you contact us directly, we may receive additional information about you
                    such as your name, email address, phone number, the contents of the message
                    and/or attachments you may send us, and any other information you may choose
                    to provide.
                    <br />
                    <br />
                    When you register for an Account, we may ask for your contact information,
                    including items such as name, company name, address, email address, and
                    telephone number.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How we use your information
                  </MKTypography>
                  <MKTypography variant="body2" color="text" component="span">
                    We use the information we collect in various ways, including to:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Provide, operate, and maintain our website
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Improve, personalize, and expand our website
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Understand and analyze how you use our website
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Develop new products, services, features, and functionality
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Communicate with you, either directly or through one of our partners,
                          including for customer service, to provide you with updates and other
                          information relating to the website, and for marketing and promotional
                          purposes
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Send you emails
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Find and prevent fraud
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Log Files
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    peopleforall.com follows a standard procedure of using log files. These files
                    log visitors when they visit websites. All hosting companies do this and a
                    part of hosting services&apos; analytics. The information collected by log
                    files include internet protocol (IP) addresses, browser type, Internet Service
                    Provider (ISP), date and time stamp, referring/exit pages, and possibly the
                    number of clicks. These are not linked to any information that is personally
                    identifiable. The purpose of the information is for analyzing trends,
                    administering the site, tracking users&apos; movement on the website, and
                    gathering demographic information.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Cookies and Web Beacons
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Like any other website, peopleforall.com uses &apos;cookies&apos;. These
                    cookies are used to store information including visitors&apos; preferences,
                    and the pages on the website that the visitor accessed or visited. The
                    information is used to optimize the users&apos; experience by customizing our
                    web page content based onvisitors&apos; browser type and/or other information.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Google DoubleClick DART Cookie
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Google is one of a third-party vendor on our site. It also uses cookies, known
                    as DART cookies, to serve ads to our site visitors based upon their visit to
                    www.website.com and other sites on the internet. However, visitors may choose
                    to decline the use of DART cookies by visiting the Google ad and content
                    network Privacy Policy at the following URL –
                    https://policies.google.com/technologies/ads
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Our Advertising Partners
                  </MKTypography>
                  <MKTypography variant="body2" color="text" component="div">
                    Some of advertisers on our site may use cookies and web beacons. Our
                    advertising partners are listed below. Each of our advertising partners has
                    their own Privacy Policy for their policies on user data. For easier access,
                    we hyperlinked to their Privacy Policies below.
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Google: https://policies.google.com/technologies/ads
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Advertising Partners Privacy Policies
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    You may consult this list to find the Privacy Policy for each of the
                    advertising partners of peopleforall.com.
                    <br />
                    <br />
                    Third-party ad servers or ad networks uses technologies like cookies,
                    JavaScript, or Web Beacons that are used in their respective advertisements
                    and links that appear on peopleforall.com, which are sent directly to
                    users&apos;browser. They automatically receive your IP address when this
                    occurs. These technologies are used to measure the effectiveness of their
                    advertising campaigns and/or to personalize the advertising content that you
                    see on websites that you visit.
                    <br />
                    <br />
                    Note that peopleforall.com has no access to or control over these cookies that
                    are used by third-party advertisers.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Third Party Privacy Policies
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    peopleforall.com&apos;s Privacy Policy does not apply to other advertisers or
                    websites. Thus, we are advising you to consult the respective Privacy Policies
                    of these third-party ad servers for more detailed information. It may include
                    their practices and instructions about how to opt-out of certain options.
                    <br />
                    <br />
                    You can choose to disable cookies through your individual browser options. To
                    know more detailed information about cookie management with specific web
                    browsers, it can be found at the browsers&apos; respective websites.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    CCPA Privacy Rights (Do Not Sell My Personal Information)
                  </MKTypography>
                  <MKTypography variant="body2" color="text" component="div">
                    Under the CCPA, among other rights, California consumers have the right to:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Request that a business that collects a consumer&apos;s personal data
                          disclose the categories and specific pieces of personal data that a
                          business has collected about consumers.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Request that a business delete any personal data about the consumer that
                          a business has collected.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          Request that a business that sells a consumer&apos;s personal data, not
                          sell the consumer&apos;s personal data.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    If you make a request, we have one month to respond to you. If you would like
                    to exercise any of these rights, please contact us.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    GDPR Data Protection Rights
                  </MKTypography>
                  <MKTypography variant="body2" color="text" component="div">
                    We would like to make sure you are fully aware of all of your data protection
                    rights. Every user is entitled to the following:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          The right to access – You have the right to request copies of your
                          personal data. We may charge you a small fee for this service.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          The right to rectification – You have the right to request that we
                          correct any information you believe is inaccurate. You also have the
                          right to request that we complete the information you believe is
                          incomplete.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          The right to erasure – You have the right to request that we erase your
                          personal data, under certain conditions.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          The right to restrict processing – You have the right to request that we
                          restrict the processing of your personal data, under certain conditions.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          The right to object to processing – You have the right to object to our
                          processing of your personal data, under certain conditions.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text">
                          The right to data portability – You have the right to request that we
                          transfer the data that we have collected to another organization, or
                          directly to you, under certain conditions.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    If you make a request, we have one month to respond to you. If you would like
                    to exercise any of these rights, please contact us.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Children&apos;s Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Another part of our priority is adding protection for children while using the
                    internet. We encourage parents and guardians to observe, participate in,
                    and/or monitor and guide their online activity.
                    <br />
                    <br />
                    peopleforall.com does not knowingly collect any Personal Identifiable
                    Information from children under the age of 13. If you think that your child
                    provided this kind of information on our website, we strongly encourage you to
                    contact us immediately and we will do our best efforts to promptly remove such
                    information from our records.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={2} px={1} mt={2}>
        <PFAFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PFAPrivacy;
