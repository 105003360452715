// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/PFALogos/PFALogo_color_large.png";

import PFAPrivacyPage from "layouts/pages/pfaPrivacy";
import PFAContactPage from "layouts/pages/pfaContact";
import PFAEntryPage from "layouts/pages/pfaEntry";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "People for All",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/peopleforall/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/PeopleForAllApp",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/peopleforallapp/",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", route: "/", component: <PFAEntryPage /> },
        // { name: "freebies", href: "https://www.creative-tim.com/templates/free" },
        // { name: "premium tools", href: "https://www.creative-tim.com/templates/premium" },
        // { name: "blog", href: "https://www.creative-tim.com/blog" },
      ],
    },
    {
      name: "resources",
      items: [
        // { name: "illustrations", href: "https://iradesign.io/" },
        { name: "newsletter", route: "/", component: <PFAEntryPage /> },
        // { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", route: "/contact", component: <PFAContactPage /> },
        // { name: "knowledge center", href: "https://www.creative-tim.com/knowledge-center" },
        // { name: "custom development", href: "https://services.creative-tim.com/" },
        // { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" },
      ],
    },
    {
      name: "legal",
      items: [
        // { name: "terms & conditions", href: "https://www.creative-tim.com/terms" },
        { name: "privacy policy", route: "/privacy", component: <PFAPrivacyPage /> },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} People For All by{" "}
      <MKTypography
        component="a"
        href="https://www.melograno.pl"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Melograno
      </MKTypography>
      .
    </MKTypography>
  ),
};
