/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// PFA Components
import PFASearchBar from "components/PFASearchBar";
import PFANavbar from "components/PFANavbar";
import PFAFooter from "components/PFAFooter";
import PFANewsletter from "layouts/sections/pfanewsletter";

// import PFAOrgInfoCard from "components/PFAOrgInfoCard";
import PFAOrgInfoCardGroup from "components/PFAOrgInfoCardGroup";
import PFAOrgFilter from "components/PFAOrgFilter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg/kelly-sikkema-XX2WTbLr3r8-unsplash.jpg";
import { Grid } from "@mui/material";

// Contexts
import { SearchAndFilterContext } from "scripts/contexts/SearchAndFilterContext/index";

function PFASearchResults() {
  const searchAndFilterContext = useContext(SearchAndFilterContext);

  const renderResults = () => {
    if (searchAndFilterContext.searchResults?.length > 0) {
      return <PFAOrgInfoCardGroup orgIDs={searchAndFilterContext.searchResults} sizeOfCard="md" />;
    }
    return (
      <MKBox my={2} p={2} display="flex" justifyContent="center" alignItems="center">
        <MKTypography variant="body2" component="span">
          No organizations found matching your criteria. Try expanding your filters or changing your
          search parameters.
        </MKTypography>
      </MKBox>
    );
  };

  return (
    <>
      <PFANavbar routes={routes} />
      <MKBox
        minHeight={{ xs: "50vh", lg: "25vh" }}
        width="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <PFASearchBar type="categorical" />
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          pt: 5,
          pb: 5,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <PFAOrgFilter />
            </Grid>
            <Grid item xs={12} lg={9}>
              {renderResults()}
            </Grid>
          </Grid>
        </Container>
      </Card>
      <PFANewsletter />
      <MKBox pt={2} px={1} mt={2}>
        <PFAFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PFASearchResults;

PFASearchResults.propTypes = {
  // action: PropTypes.string,
  // subject: PropTypes.string,
  // target: PropTypes.string,
};

PFASearchResults.defaultProps = {
  // action: "donate",
  // subject: "clothing, shoes and accessories",
  // target: "homeless people",
};
