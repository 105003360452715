import React from "react";
// import React, { useState, useEffect } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// PFA Components
import PFAOrgInfoCard from "components/PFAOrgInfoCard";
// import DB from "scripts/DB";

export default function PFAOrgInfoCardGroup(props) {
  const { orgIDs, sizeOfCard } = props;

  if (orgIDs.length === 0) {
    return <span />;
  }

  if (orgIDs.length > 0) {
    return (
      <>
        {orgIDs.map((element) => (
          <PFAOrgInfoCard orgID={element} size={sizeOfCard} key={element} />
        ))}
      </>
    );
  }
}

PFAOrgInfoCardGroup.propTypes = {
  orgIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
  sizeOfCard: PropTypes.oneOf(["sm", "md", "lg"]).isRequired,
};
