import React from "react";

// nodejs library that concatenates classes
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";

import volunteerIcon from "assets/images/icons/volunteer.png";
import donateCashIcon from "assets/images/icons/donate-cash.png";
import donateGoodsPeopleIcon from "assets/images/icons/donate-goods-people.png";
import donateGoodsChildrenIcon from "assets/images/icons/donate-goods-children.png";
import donateGoodsAnimalsIcon from "assets/images/icons/donate-goods-animals.png";
import onePercentTaxDonationIcon from "assets/images/icons/donate-one-percent - white.png";

export default function PFAActionButton(props) {
  const { actionType, children, size, ...rest } = props;

  let pfaActionButtonIcon;
  let pfaActionButtonText;

  switch (actionType) {
    case "volunteer":
      pfaActionButtonIcon = volunteerIcon;
      pfaActionButtonText = <div>Volunteer</div>;
      break;
    case "donateCash":
      pfaActionButtonIcon = donateCashIcon;
      pfaActionButtonText = <div>Donate Cash</div>;
      break;
    case "donateGoodsPeople":
      pfaActionButtonIcon = donateGoodsPeopleIcon;
      pfaActionButtonText = <div>Donate Goods</div>;
      break;
    case "donateGoodsChildren":
      pfaActionButtonIcon = donateGoodsChildrenIcon;
      pfaActionButtonText = <div>Donate Goods</div>;
      break;
    case "donateGoodsAnimals":
      pfaActionButtonIcon = donateGoodsAnimalsIcon;
      pfaActionButtonText = <div>Donate Goods</div>;
      break;
    case "donateOnePercent":
      pfaActionButtonIcon = onePercentTaxDonationIcon;
      pfaActionButtonText = <div>Donate 1% of Tax</div>;
      break;
    default:
  }

  if (size === "small") {
    return (
      <MKButton {...rest} size="small" sx={{ borderRadius: 7 }}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <img src={pfaActionButtonIcon} alt="action button icon" height="40px" />
          </Grid>
        </Grid>
      </MKButton>
    );
  }
  if (size === "medium") {
    return (
      <MKButton {...rest} size="medium" sx={{ borderRadius: 7 }}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <img src={pfaActionButtonIcon} alt="action button icon" height="40px" />
          </Grid>
          <Grid item xs={12} md={8}>
            {pfaActionButtonText}
            {children}
          </Grid>
        </Grid>
      </MKButton>
    );
  }
}

// Typechecking props for the PFAActionButton
PFAActionButton.propTypes = {
  actionType: PropTypes.oneOf([
    "volunteer",
    "donateCash",
    "donateGoodsPeople",
    "donateGoodsChildren",
    "donateGoodsAnimals",
    "donateOnePercent",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "action",
    "info",
    "success",
    "warning",
    "danger",
    "volunteering",
    "white",
    "transparent",
    "taxOffice",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node,
};

// Setting default values for the props of PFAActionButton
PFAActionButton.defaultProps = {
  actionType: "donateCash".isRequired,
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
  children: null,
};
