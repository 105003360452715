// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";

// Material Kit 2 PRO React example components
import PFANavbarRoot from "components/PFANavbar/PFANavbarRoot";

// @mui material components

// Routes
import * as PFAroutes from "routes";

function PFANavbar({ brand, routes, transparent, light, action, sticky, relative, center }) {
  return (
    <MKBox opacity={1} bgColor="light" sx={{ position: "sticky", top: 0, zIndex: 10 }}>
      <PFANavbarRoot
        brand={brand}
        routes={routes}
        transparent={transparent}
        light={light}
        action={action}
        sticky={sticky}
        relative={relative}
        center={center}
      />
    </MKBox>
  );
}

PFANavbar.defaultProps = {
  brand: (
    <MKBox display="flex">
      People for All{" "}
      <MKBox pl={1} mt={-1} fullWidth textAlign="right">
        <MKBadge badgeContent="beta" size="xs" container color="light" />
      </MKBox>
    </MKBox>
  ),
  transparent: true,
  light: false,
  action: false,
  sticky: true,
  relative: true,
  center: false,
  routes: PFAroutes,
};

// Typechecking props for the DefaultNavbar
PFANavbar.propTypes = {
  brand: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/forbid-prop-types
  routes: PropTypes.arrayOf(PropTypes.object),
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default PFANavbar;
