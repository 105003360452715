// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PFACollapse({ title, open, children, size, heightLimit, ...rest }) {
  return (
    <MKBox mb={size === "md" ? 2 : 0}>
      <MKBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={size === "md" ? 2 : 0}
        sx={{
          cursor: "pointer",
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <MKTypography
          variant={size === "md" ? "h6" : "body2"}
          color={open ? "dark" : "text"}
          sx={{ userSelect: "none" }}
          component="span"
        >
          {title}
        </MKTypography>
        <MKBox color={open ? "dark" : "text"}>
          <Icon sx={{ fontWeight: "bold" }} fontSize="small">
            {open ? "remove" : "add"}
          </Icon>
        </MKBox>
      </MKBox>
      <Collapse timeout={200} in={open}>
        <MKBox
          py={2}
          lineHeight={1}
          maxHeight={heightLimit}
          sx={heightLimit ? { display: "flex", flexDirection: "column", overflow: "hidden" } : {}}
        >
          <MKTypography
            variant="body2"
            color="text"
            opacity={0.8}
            fontWeight="regular"
            paragraph={false}
            component="span"
            sx={{ overflowY: "auto" }}
          >
            {children}
          </MKTypography>
        </MKBox>
      </Collapse>
    </MKBox>
  );
}

// Typechecking props for the PFACollapse
PFACollapse.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["sm", "md"]),
  heightLimit: PropTypes.string,
};

PFACollapse.defaultProps = {
  size: "md",
  heightLimit: "",
};

export default PFACollapse;
