import React, { useState, useEffect } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// PFA Components
import PFACollapse from "components/PFACollapse";
import PFAFilterElementList from "components/PFAFilterElementList";

export default function PFAFilterGroup(props) {
  const { contents, onChange, getCheckedValue, getIndeterminateValue, title, heightLimit } = props;
  const [collapsedPFACollapses, setCollapsedState] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filterValues, setFilterValues] = useState([]);

  useEffect(async () => {
    setFilterValues(contents);
  }, [contents]);

  function handleCollapsedState(key) {
    const sel = collapsedPFACollapses.map((x) => x);
    const find = sel.indexOf(key);
    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push(key);
    }
    setCollapsedState(sel);
  }

  function getCollapsedState(key) {
    const find = collapsedPFACollapses.indexOf(key);
    if (find > -1) {
      return true;
    }
    return false;
  }

  if (contents?.length === 0) {
    return <> </>;
  }

  return (
    <PFACollapse
      title={title}
      open={getCollapsedState(title)}
      onClick={() => handleCollapsedState(title)}
      size="md"
      key={title}
      heightLimit={heightLimit}
    >
      <MKBox ml={1}>
        <PFAFilterElementList
          contents={contents}
          onChange={onChange}
          getCheckedValue={getCheckedValue}
          getIndeterminateValue={getIndeterminateValue}
        />
      </MKBox>
    </PFACollapse>
  );
}

PFAFilterGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contents: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  getCheckedValue: PropTypes.func.isRequired,
  getIndeterminateValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  heightLimit: PropTypes.string,
};

PFAFilterGroup.defaultProps = {
  contents: [],
  heightLimit: "",
};
