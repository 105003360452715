// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// react-router-dom components
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/examples/blog2.jpg";

function PFAContactForm() {
  const user = "contact";
  const domain = "peopleforall.com";
  const address = `${user}@${domain}`;
  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item px={{ xs: 2, lg: 6 }}>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Write to us!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Questions? Feedback? Support? Sponsorship?
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={3}>
                        <MKInput
                          variant="standard"
                          label="Your name"
                          placeholder="Full Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={3}>
                        <MKInput
                          variant="standard"
                          label="Your email"
                          placeholder="me@example.com"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={3}>
                        <MKInput
                          variant="standard"
                          label="Your message"
                          placeholder="I want to say that..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={8}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton variant="gradient" color="info">
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.info.main, 0.8),
                      rgba(gradients.info.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  flexWrap
                >
                  <MKBox px={6} py={{ xs: 2, sm: 2 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Fill up the form and we will get back to you as soon as possible.
                    </MKTypography>

                    {/* <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        We don&apos;t have a phone yet :)
                      </MKTypography>
                    </MKBox> */}
                    <MKTypography variant="body2" color="white" opacity={0.8}>
                      Or email us directly:
                    </MKTypography>
                    <MKBox
                      display="flex"
                      color="white"
                      p={1}
                      component="a"
                      // component={Link}
                      // to={`mailto:${address}`}
                      href={`mailto:${address}`}
                      target="_blank"
                    >
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        {address}
                      </MKTypography>
                    </MKBox>
                    {/* <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography> 
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        We don&apos;t have a physical address yet :)
                      </MKTypography>
                    </MKBox> */}
                    <MKBox mt={3}>
                      <MKTypography variant="body2" color="white" opacity={0.8}>
                        Follow us on social media:
                      </MKTypography>
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                        href="https://www.facebook.com/peopleforall/"
                        target="_blank"
                      >
                        <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                        href="https://twitter.com/PeopleForAllApp"
                        target="_blank"
                      >
                        <i className="fab fa-twitter" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                        href="https://www.instagram.com/peopleforallapp/"
                        target="_blank"
                      >
                        <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PFAContactForm;
