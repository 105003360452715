/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";

function PFANewsletter() {
  return (
    <MKBox
      position="relative"
      variant="gradient"
      bgColor="dark"
      mt={{ xs: 6, lg: 12 }}
      mb={5}
      py={{ xs: 6, lg: 12 }}
    >
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        // left={0}
        width={{ xs: "100%" }}
        height={{ xs: "auto", lg: "auto" }}
        maxHeight="500px"
        opacity={0.6}
      />
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} sx={{ my: "auto", pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}>
            <MKTypography variant="h3" color="white" mb={3}>
              Keep up to date!
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={2} opacity={0.8}>
              Subscribe to the People For All newsletter and get updates in your inbox every week!
            </MKTypography>
            <MKBox display="flex" alignItems="center" />
          </Grid>
          <Grid
            item
            flexDirection="column"
            justifyContent="center"
            ml="auto"
            xs={12}
            lg={6}
            sx={{ pl: { xs: 3, lg: 6 }, pr: { xs: 3, lg: 6 }, pb: 4 }}
          >
            <MKBox component="form" method="" action="">
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <MKInput label="Your Email..." fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MKButton variant="gradient" color="info" fullWidth sx={{ height: "100%" }}>
                    Subscribe
                  </MKButton>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PFANewsletter;
