/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// PFA Components
import PFANavbar from "components/PFANavbar";
import PFAFooter from "components/PFAFooter";
import PFAContactForm from "components/PFAContactForm";

// Presentation page sections

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg/kelly-sikkema-XX2WTbLr3r8-unsplash.jpg";

function PFAContact() {
  return (
    <>
      <PFANavbar routes={routes} sticky />
      <MKBox
        minHeight="25vh"
        width="100%"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <MKBox component="section" pt={{ xs: 4, lg: 8 }} pb={{ xs: 2, lg: 6 }}>
        <PFAContactForm />
      </MKBox>
      <MKBox pt={2} px={1} mt={2}>
        <PFAFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PFAContact;
