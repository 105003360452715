import React, { useContext } from "react";

// react-router components
// import { Link } from "react-router-dom";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Card from "@mui/material/Card";
import MKButton from "components/MKButton";

// PFA Components
import PFAFilterGroup from "components/PFAFilterGroup";

// Contexts
import { SearchAndFilterContext } from "scripts/contexts/SearchAndFilterContext/index";

export default function PFAOrgFilter() {
  const searchAndFilterContext = useContext(SearchAndFilterContext);

  const renderResults = () => {
    if (searchAndFilterContext.filterOptions?.loaded) {
      return (
        <>
          <PFAFilterGroup
            title="Beneficiaries..."
            contents={searchAndFilterContext.filterOptions.targets}
            onChange={searchAndFilterContext.filtersHandleCheckedState}
            getCheckedValue={searchAndFilterContext.filtersGetCheckedState}
            getIndeterminateValue={searchAndFilterContext.filtersGetIndeterminateState}
            heightLimit="350px"
          />
          <PFAFilterGroup
            title="Accepted donation types..."
            contents={searchAndFilterContext.filterOptions.subjects}
            onChange={searchAndFilterContext.filtersHandleCheckedState}
            getCheckedValue={searchAndFilterContext.filtersGetCheckedState}
            getIndeterminateValue={searchAndFilterContext.filtersGetIndeterminateState}
            heightLimit="500px"
          />
          <PFAFilterGroup
            title="Location..."
            contents={searchAndFilterContext.filterOptions.locations}
            onChange={searchAndFilterContext.filtersHandleCheckedState}
            getCheckedValue={searchAndFilterContext.filtersGetCheckedState}
            getIndeterminateValue={searchAndFilterContext.filtersGetIndeterminateState}
            heightLimit="500px"
          />
        </>
      );
    }
    return (
      <MKTypography variant="h5" component="span">
        {" "}
      </MKTypography>
    );
  };

  return (
    <MKBox position="sticky" top="100px">
      <Card
        sx={{
          p: 2,
          my: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h5" component="span">
          Refine your results
        </MKTypography>
        {renderResults()}
        <MKButton
          // component={Link}
          variant="gradient"
          color="info"
          fullWidth
          onClick={searchAndFilterContext.applyFiltersButtonPressed}
        >
          Apply Filters
        </MKButton>
      </Card>
    </MKBox>
  );
}
