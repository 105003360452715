import React, { useState } from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// @mui material components
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// PFA Components
import PFACollapse from "components/PFACollapse";

function BaseFilterElement(props) {
  const { element, onChange, getCheckedValue, getIndeterminateValue } = props;
  const handleCheckedStateBase = () => {
    onChange(element);
  };
  return (
    <FormControlLabel
      key={element.itemName}
      label={
        <MKTypography variant="body2" component="span">
          {element.itemName}
        </MKTypography>
      }
      control={
        <Checkbox
          checked={getCheckedValue(element)}
          color="secondary"
          indeterminate={getIndeterminateValue(element)}
          onChange={handleCheckedStateBase}
          name={element.itemName}
          sx={{ py: "4px" }}
        />
      }
      variant="standard"
      sx={{ display: "flex" }}
    />
  );
}

BaseFilterElement.propTypes = {
  element: PropTypes.shape({
    itemName: PropTypes.string,
    // itemID: PropTypes.number,
    // key: PropTypes.string,
    // parentID: PropTypes.arrayOf(PropTypes.number),
    // parentKey: PropTypes.arrayOf(PropTypes.string),
    // level: PropTypes.number,
    // checked: PropTypes.bool.isRequired,
    // indeterminate: PropTypes.bool.isRequired,
    // scope: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  getCheckedValue: PropTypes.func.isRequired,
  getIndeterminateValue: PropTypes.func.isRequired,
};

BaseFilterElement.defaultProps = {};

export default function PFAFilterElementList(props) {
  const { contents, onChange, getCheckedValue, getIndeterminateValue } = props;

  const [collapsedPFACollapses, setCollapsedState] = useState([]);

  function handleCollapsedState(key) {
    const sel = collapsedPFACollapses.map((x) => x);
    const find = sel.indexOf(key);
    if (find > -1) {
      sel.splice(find, 1);
    } else {
      sel.push(key);
    }
    setCollapsedState(sel);
  }

  function getCollapsedState(key) {
    const find = collapsedPFACollapses.indexOf(key);
    if (find > -1) {
      return true;
    }
    return false;
  }

  if (contents.length === 0) {
    return <>...</>;
  }

  return (
    <>
      {contents.map((element) =>
        element.items?.length > 0 ? (
          <PFACollapse
            title={
              <BaseFilterElement
                key={element.itemName}
                element={element}
                onChange={onChange}
                getCheckedValue={getCheckedValue}
                getIndeterminateValue={getIndeterminateValue}
              />
            }
            open={getCollapsedState(`${element.itemName}_${element.itemID}_collapse`)}
            onClick={() => handleCollapsedState(`${element.itemName}_${element.itemID}_collapse`)}
            size="sm"
            key={`${element.itemName}_${element.itemID}_collapse`}
          >
            <MKBox ml={2} mt={-1}>
              <PFAFilterElementList
                contents={element.items}
                onChange={onChange}
                getCheckedValue={getCheckedValue}
                getIndeterminateValue={getIndeterminateValue}
              />
              {/* RECURSION ABOVE */}
            </MKBox>
          </PFACollapse>
        ) : (
          <BaseFilterElement
            key={element.itemName}
            element={element}
            onChange={onChange}
            getCheckedValue={getCheckedValue}
            getIndeterminateValue={getIndeterminateValue}
          />
        )
      )}
    </>
  );
}

PFAFilterElementList.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      itemName: PropTypes.string,
    }).isRequired
  ),
  onChange: PropTypes.func.isRequired,
  getCheckedValue: PropTypes.func.isRequired,
  getIndeterminateValue: PropTypes.func.isRequired,
};

PFAFilterElementList.defaultProps = {
  contents: [],
};

/* recursion olmadan calisan kod, silme burda dursun simdilik
      {filterValues.map((element) => (
        <FormControlLabel
          key={element.itemName}
          label={
            <MKTypography variant="body2" component="span">
              {element.itemName}
            </MKTypography>
          }
          control={
            <Checkbox
              // checked={getCheckedState(element.itemName)}
              // onChange={handleActionCheckedState}
              name={element.itemName}
              sx={{ py: "5px" }}
            />
          }
          variant="standard"
          sx={{ display: "flex" }}
        />
      ))} */
