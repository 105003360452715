// nodejs library to set properties for components
import PropTypes from "prop-types";

import React, { useState, useContext } from "react";

// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
// eslint-disable-next-line no-unused-vars
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";

// @mui icons
import SearchIcon from "@mui/icons-material/Search";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// PFA
// import DB from "scripts/DB";

// Contexts
import { SearchAndFilterContext } from "scripts/contexts/SearchAndFilterContext/index";

function PFASearchBar(props) {
  const { type } = props;
  const searchAndFilterContext = useContext(SearchAndFilterContext);

  // Define states for dropdown options and get them from the DB
  const [helpActionValues, setHelpActionValues] = useState([]);
  const [helpSubjectValues, setHelpSubjectValues] = useState([]);
  const [helpTargetValues, setHelpTargetValues] = useState([]);

  // Change handlers for search bar dropdowns
  const handleChange = (event) => {
    const value = {
      action: searchAndFilterContext.currentSearchBarSelection.action,
      subject: searchAndFilterContext.currentSearchBarSelection.subject,
      target: searchAndFilterContext.currentSearchBarSelection.target,
    };
    // construct values:
    if (event.target.name === "action") {
      value.action = event.target.value;
    }
    if (event.target.name === "subject") {
      value.subject = event.target.value;
    }
    if (event.target.name === "target") {
      value.target = event.target.value;
    }
    searchAndFilterContext.updateSearchBarSelection(value);
  };

  // Update currentSearchValuesContext with the selected values once Search button is pressed
  const handleSearchButtonClick = () => {
    searchAndFilterContext.searchButtonPressed();
  };

  // Render loader if data options are not loaded yet
  if (!searchAndFilterContext.searchBarOptions.loaded) {
    return <Grid container>...</Grid>;
  }

  // Render fully if data options are loaded
  if (type === "categorical" && searchAndFilterContext.searchBarOptions.loaded) {
    if (
      searchAndFilterContext.searchBarOptions.actions !== helpActionValues &&
      searchAndFilterContext.searchBarOptions.subjects !== helpSubjectValues &&
      searchAndFilterContext.searchBarOptions.targets !== helpTargetValues
    ) {
      setHelpActionValues(searchAndFilterContext.searchBarOptions.actions);
      setHelpSubjectValues(searchAndFilterContext.searchBarOptions.subjects);
      setHelpTargetValues(searchAndFilterContext.searchBarOptions.targets);
    }

    return (
      <MKBox
        py={1.5}
        px={2}
        my={0}
        mx="auto"
        width="90%"
        borderRadius="xl"
        shadow="md"
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1}
        component="form"
        method=""
        action=""
        bgColor="white"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <InputLabel id="label1" shrink>
              How can you help?
            </InputLabel>
            <Select
              variant="standard"
              fullWidth
              labelId="label1"
              id="select-for-how"
              value={searchAndFilterContext.currentSearchBarSelection.action}
              label=" "
              name="action"
              onChange={handleChange}
            >
              {helpActionValues.map((x) => (
                <MenuItem key={x.itemName} value={x.itemName}>
                  {x.itemName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} lg={3}>
            <InputLabel id="label2" shrink>
              With what can you help?
            </InputLabel>
            <Select
              variant="standard"
              fullWidth
              labelId="label2"
              id="select-for-with-what"
              value={searchAndFilterContext.currentSearchBarSelection.subject}
              label=" "
              name="subject"
              onChange={handleChange}
            >
              {helpSubjectValues.map((x) => (
                <MenuItem key={x.itemName} value={x.itemName}>
                  {x.itemName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} lg={3}>
            <InputLabel id="label3" shrink>
              Whom can you help?
            </InputLabel>
            <Select
              variant="standard"
              fullWidth
              labelId="demlabel3"
              id="select-for-to-whom"
              value={searchAndFilterContext.currentSearchBarSelection.target}
              label=" "
              name="target"
              onChange={handleChange}
            >
              {helpTargetValues.map((x) => (
                <MenuItem key={x.itemName} value={x.itemName}>
                  {x.itemName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKButton
              component={Link}
              to="/search"
              variant="gradient"
              color="warning"
              fullWidth
              onClick={handleSearchButtonClick}
              sx={{ height: "100%" }}
            >
              Search
            </MKButton>
          </Grid>
        </Grid>
      </MKBox>
    );
  }
  if (type === "intelligent") {
    return (
      <MKBox
        py={1.5}
        px={2}
        my={0}
        mx="auto"
        width="80%"
        borderRadius="xl"
        shadow="md"
        position="relative"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1}
        component="form"
        method=""
        action=""
        bgColor="white"
      >
        {/* <Grid container spacing={2}>
            <Grid item xs={12} md={10}> */}
        <MKInput
          variant="standard"
          fullWidth
          label=" "
          type="search"
          placeholder="Read books to children without parents..."
          sx={{ height: "100%", lineHeight: "0.9" }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        {/* </Grid>
            <Grid item xs={12} md={2}>
              <MKButton variant="gradient" color="warning" fullWidth sx={{ height: "100%" }}>
                Search
              </MKButton>
            </Grid>
          </Grid> */}
      </MKBox>
    );
  }
}

export default PFASearchBar;

PFASearchBar.propTypes = {
  type: PropTypes.oneOf(["intelligent", "categorical"]),
};

PFASearchBar.defaultProps = {
  type: "intelligent",
};
