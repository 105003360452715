import axios from "axios";

let apiURL; // = "http://localhost:3001/api/";
// const apiURL = "https://peopleforall-backend.herokuapp.com/api/";

if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION_REMOTE") {
  apiURL = "https://peopleforall-backend.herokuapp.com/api/";
} else if (process.env.REACT_APP_ENVIRONMENT === "LOCAL_MACHINE") {
  apiURL = "http://localhost:3001/api/";
}
// eslint-disable-next-line no-console
console.log(process.env.REACT_APP_ENVIRONMENT);

export default class DB {
  // Get the organization details from the API
  static async GetOrgDetails(orgID) {
    try {
      // eslint-disable-next-line
      console.log(`Sending request to server: Get organization details for OrgID: ${orgID}`);
      const response = await axios.get(`${apiURL}org/details/${orgID}`);
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  // Get the organization contact address and the related details, transform the data to be human readable such as telephone codes, country names etc.
  static async GetOrgContactAddress(orgID) {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get organization contact address for OrgID: ${orgID}`
      );
      const response = await axios.get(`${apiURL}org/contact_address/${orgID}`); // Get the address
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  static async GetHelpActionValues() {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get help action values list`
      );
      const response = await axios.get(`${apiURL}help_actions`); // Get the address
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  static async GetHelpSubjectValues() {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get help subject values list`
      );
      const response = await axios.get(`${apiURL}help_subjects`); // Get the address
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  static async GetHelpTargetValues() {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get help target values list`
      );
      const response = await axios.get(`${apiURL}help_targets`); // Get the address
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  static async GetFilterValuesActions() {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get filter values for actions`
      );
      const response = await axios.get(`${apiURL}filter_options/actions`); // Get the address
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  static async GetFilterValuesBeneficiaries(orgIDs = []) {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get filter values for beneficiaries`
      );
      let response;
      if (orgIDs.length === 0) {
        response = await axios.get(`${apiURL}filter_options/beneficiaries/inuse`); // Get the address
      } else {
        response = await axios.get(`${apiURL}filter_options/beneficiaries/inuse`); // Get the address
      }
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  static async GetFilterValuesGoods() {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get filter values for goods`
      );
      const response = await axios.get(`${apiURL}filter_options/goods/inuse`); // Get the address
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  static async GetFilterValuesLocations() {
    try {
      // eslint-disable-next-line
      console.log(
        `Sending request to server: Get filter values for locations`
      );
      const response = await axios.get(`${apiURL}filter_options/locations/inuse`); // Get the address
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  // Receives the action, subject and target (mostly coming from PFASearchBar), sends back the list of OrgIDs  which meets the criteria and the filter values
  static async GetSearchResults(actionID, subjectID, targetID) {
    try {
      // eslint-disable-next-line
      console.log(
        "Sending request to server: Get search results for: Action: ",
        actionID,
        ", Subject: ",
        subjectID,
        ", Target: ",
        targetID
      );

      const params = new URLSearchParams({ actionID, subjectID, targetID });

      // const response = await axios.get(`${apiURL}search/${action}/${subject}/${target}`);
      const response = await axios.get(`${apiURL}search/`, { params });
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }

  // Receives the action, subject and target and filter options, sends back the list of OrgID which meets the criteria
  static async GetFilteredResults(
    actionID,
    subjectID,
    targetID,
    targetFilterIDs,
    subjectFilterIDs,
    locationFilterIDs
  ) {
    try {
      // eslint-disable-next-line
      console.log(
        "Sending request to server: Get filtered search results for: Action: ",
        actionID,
        ", Subject: ",
        subjectID,
        ", Target: ",
        targetID,
        ", Target filters: ",
        targetFilterIDs,
        ", Subject filters: ",
        subjectFilterIDs,
        ", Location filters: ",
        locationFilterIDs
      );

      const params = new URLSearchParams({
        actionID,
        subjectID,
        targetID,
        targetFilterIDs,
        subjectFilterIDs,
        locationFilterIDs,
      });

      // const response = await axios.get(`${apiURL}search/${action}/${subject}/${target}`);
      const response = await axios.get(`${apiURL}apply_filters/`, { params });
      return response.data;
    } catch (error) {
      // TODO: Instead of returning the error, return a generic replacement value (while making sure that the front end understands that it's in fact an error)
      return error;
    }
  }
}
